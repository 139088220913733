export const createDynamicLink = (isProdMode) => {
  console.log(isProdMode)
    const link = document.createElement("link");
    link.href = isProdMode ? `${process.env.PUBLIC_URL}/style_prod.css?${process.env.REACT_APP_Current_Version}` : `${process.env.PUBLIC_URL}/style_dev.css?${process.env.REACT_APP_Current_Version}`;
    link.rel = "stylesheet";
    link.type = "text/css";

    const gtmId = isProdMode ? process.env.REACT_APP_PROD_G_TAG_ID:"GTM-WZ3JJ7KC"
    const script_G_T_M = document.createElement("script");
    script_G_T_M.innerHTML = `
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "${gtmId.toString()}");`;


      const noscript_G_T_M = document.createElement("noscript");
      const iframe = document.createElement("iframe");
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${isProdMode ?  process.env.REACT_APP_PROD_G_TAG_ID : 'GTM-WZ3JJ7KC'}`;
      iframe.height = "0";
      iframe.width = "0";
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";
      noscript_G_T_M.appendChild(iframe);

    document.body.appendChild(noscript_G_T_M);
    document.head.appendChild(script_G_T_M);
    document.head.appendChild(link);

if(isProdMode){
    const script_LinkeDin = document.createElement("script");
    script_LinkeDin.type = "text/javascript";
    script_LinkeDin.innerHTML = `
    _linkedin_partner_id = "689713";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;

    const script_Analytics = document.createElement("script");
    script_Analytics.type = "text/javascript";
    script_Analytics.async = true;
    script_Analytics.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    const firstScript = document.getElementsByTagName("script")[0];

    const noscript_LinkeDinImg = document.createElement("noscript");
    const img = document.createElement("img");
    img.height = "1";
    img.width = "1";
    img.style.display = "none";
    img.alt = "";
    img.src = `https://px.ads.linkedin.com/collect/?pid=689713&fmt=gif`;
    noscript_LinkeDinImg.appendChild(img);


    document.body.appendChild(script_LinkeDin);
    firstScript.parentNode.insertBefore(script_Analytics, firstScript);
    document.body.appendChild(noscript_LinkeDinImg);
}
  };