
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DynamicTitle = ({ title="InnSpire.One", children }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [title]);

  return <>{children}</>;
};

export default DynamicTitle;
