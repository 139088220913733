import * as teamComActionTypes from "./actions/teamComActionTypes";

const initialState = {
  other_loading: false,
  other_error: "",
  channels: {
    loading: false,
    data: [],
    selectedChannels: [],
    error: "",
    messages: [],
  },
  directMessage: {
    loading: false,
    data: [],
    selectedUsers: [],
    error: "",
    messages: [],
  },
  infoForChatInp:null
};

function update_or_add_channel_in_list(
  lst,
  channel,
  add_if_not_present = true
) {
  if (!lst) return add_if_not_present ? [channel] : [];

  var _old_channel_indx = lst.findIndex(
    (_channel) => _channel.conversation_id == channel.conversation_id
  );

  if (_old_channel_indx == -1)
    return add_if_not_present ? [channel, ...lst] : [...lst];

  var _filtered_channels = lst.filter(
    (_channel) => _channel.conversation_id != channel.conversation_id
  );
  _filtered_channels.splice(_old_channel_indx, 0, channel);

  return _filtered_channels;
}

function update_or_add_dmus_in_list(lst, dmu, add_if_not_present = true) {
  if (!lst) return add_if_not_present ? [dmu] : [];

  var _old_dmu_indx = lst.findIndex((_dmu) => _dmu.dmu_id == dmu.dmu_id);

  if (_old_dmu_indx == -1) return add_if_not_present ? [...lst, dmu] : [...lst];

  var _filtered_dmus = lst.filter((_dmu) => _dmu.dmu_id != dmu.dmu_id);
  _filtered_dmus.splice(_old_dmu_indx, 0, dmu);

  return _filtered_dmus;
}

function find_and_update(list, id, updated_props) {
  let _old_msg_indx = list.findIndex((_msg) => _msg.id == id);

  if (_old_msg_indx == -1) return list;

  let updated_msg = { ...list[_old_msg_indx], ...updated_props };

  var _filtered_msgs = list.filter((_msg) => _msg.id != id);
  _filtered_msgs.splice(_old_msg_indx, 0, updated_msg);

  return _filtered_msgs;
}

function update_or_add_msgs(lst, msg, add_if_not_present) {
  if (!lst) return add_if_not_present ? [msg] : [];

  let id = msg.feId || msg.id;
  var _old_msg_indx = lst.findIndex((_msg) => _msg.id == id);

  if (_old_msg_indx == -1) return add_if_not_present ? [...lst, msg] : [...lst];

  var _filtered_msgs = lst.filter((_msg) => _msg.id != id);
  _filtered_msgs.splice(_old_msg_indx, 0, msg);

  return _filtered_msgs;
}

const teamComReducer = (state = initialState, action) => {
  switch (action.type) {
    case teamComActionTypes.FETCH_TEAM_COM_CHANNELS_REQUEST:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: true,
        },
      };

    case teamComActionTypes.FETCH_TEAM_COM_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          data: action.payload,
          error: "",
        },
      };

    case teamComActionTypes.FETCH_TEAM_COM_CHANNELS_ERROR:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          error: action.payload,
        },
      };

    case teamComActionTypes.ADD_TEAM_COM_CHANNEL_REQUEST:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: true,
        },
      };
      case teamComActionTypes.SET_INFO_FOR_CHAT_INP:
        return {
          ...state,
          infoForChatInp:action.payload
        };

    case teamComActionTypes.ADD_TEAM_COM_CHANNEL_SUCCESS:
      let newChanData = update_or_add_channel_in_list(
        state.channels.data,
        action.payload
      );
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          data: newChanData,
          error: "",
        },
      };

    case teamComActionTypes.ADD_TEAM_COM_CHANNEL_ERROR:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          error: action.payload,
        },
      };

    case teamComActionTypes.FETCH_DMU_REQUEST:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          loading: true,
        },
      };

    case teamComActionTypes.FETCH_DMU_SUCCESS:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          loading: false,
          data: action.payload,
          error: "",
        },
      };


      case teamComActionTypes.UPDATE_STAFF_UNREAD_MSG_COUNT:
        return {
          ...state,
          directMessage: {
            ...state.directMessage, 
            data: action.payload,
          },
        };

    // case teamComActionTypes.APPEND_TO_DMUS_LIST:
    //   return {
    //     ...state,
    //     directMessage: {
    //       ...state.directMessage,
    //       loading: false,
    //       data: [...(state.directMessage.data || []), ...action.payload],
    //       error: "",
    //     },
    //   };

    case teamComActionTypes.FETCH_DMU_ERROR:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          loading: false,
          error: action.payload,
        },
      };

    case teamComActionTypes.SET_TEAM_COM_SELECTED_CHANNEL:
      let filteredChannels = update_or_add_channel_in_list(
        state.channels.selectedChannels,
        action.payload
      );
      // let filteredChannels = state.channels.selectedChannels.includes(action.payload)
      //   ? [...state.channels.selectedChannels]
      //   : [...state.channels.selectedChannels, action.payload];
      return {
        ...state,
        channels: {
          ...state.channels,
          selectedChannels: filteredChannels,
        },
        directMessage: {
          ...state.directMessage,
          selectedUsers: [],
        },
        infoForChatInp:null
      };

    case teamComActionTypes.UNSET_SELECTED_CHANNEL:
      return {
        ...state,
        channels: {
          ...state.channels,
          messages: [],
          error: "",
          selectedChannels: [
            ...state.channels.selectedChannels.filter(
              (_c) => _c.conversation_id != action.payload
            ),
          ],
        },
        infoForChatInp:null
      };

    case teamComActionTypes.SET_SELECTED_DMU:
      
      let filteredDmus = [action.payload.dmu]
   if(!action.payload?.setExact){
    filteredDmus = update_or_add_dmus_in_list(
      state.directMessage.selectedUsers,
      action.payload.dmu
    );
   }
      // let filteredDmus = state.directMessage.selectedUsers.find(
      //   (dmu) => dmu.name == action.payload.name
      // )
      //   ? [...state.directMessage.selectedUsers]
      //   : [...state.directMessage.selectedUsers, action.payload];
      return {
        ...state,
        channels: {
          ...state.channels,
          selectedChannels: [],
        },
        directMessage: {
          ...state.directMessage,
          selectedUsers: filteredDmus,
          // data:state.directMessage.data.map(user =>
          //   user.id === action.payload.id ? { ...user, unread_msg_count: 0 } : user
          // ),
        },
        infoForChatInp:null
      };

      case teamComActionTypes.SET_UNREAD_COUNT:
   
        return {
          ...state,
          directMessage: {
            ...state.directMessage,
            data:state.directMessage.data.map(user =>
              user.id === action.payload ? { ...user, unread_msg_count: 0 } : user
            ),
          },
        };
  
    case teamComActionTypes.UNSET_SELECTED_DMU:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          messages: [],
          error: "",
          selectedUsers: [
            ...state.directMessage.selectedUsers.filter(
              (_dmu) => _dmu.id != action.payload
            ),
          ],
        },
        infoForChatInp:null
      };

    case teamComActionTypes.FETCH_CHANNEL_MESSAGES_REQUEST:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: true,
          error: "",
        },
      };

    case teamComActionTypes.FETCH_CHANNEL_MESSAGES_SUCCESS:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          error:
            action.payload.length == 0
              ? "No chat messages found"
              : action.payload.length < 10
              ? "--"
              : "",
          messages: action.payload,
        },
      };

    case teamComActionTypes.PREPEND_CHANNEL_MESSAGES:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          error: action.payload.length < 10 ? "--" : "",
          messages: [...action.payload, ...(state.channels.messages || [])],
        },
      };

    case teamComActionTypes.UNSET_CHANNEL_MESSAGES:
      return {
        ...state,
        channels: {
          ...state.channels,
          messages: [],
          error: "",
        },
      };

    case teamComActionTypes.FETCH_CHANNEL_MESSAGES_FAILURE:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          error: action.payload,
        },
      };

    case teamComActionTypes.FETCH_DMU_MESSAGES_REQUEST:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          loading: true,
          error: "",
        },
      };

    case teamComActionTypes.FETCH_DMU_MESSAGES_SUCCESS:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          loading: false,
          error:
            action.payload.length == 0
              ? "No chat messages found"
              : action.payload.length < 10
              ? "--"
              : "",
          messages: action.payload,
        },
      };

    case teamComActionTypes.FETCH_DMU_MESSAGES_FAILURE:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          loading: false,
          error: action.payload,
        },
      };

    case teamComActionTypes.UNSET_DIRECT_MESSAGES:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          messages: [],
          error: "",
        },
      };

    case teamComActionTypes.PREPEND_DIRECT_MESSAGES:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          loading: false,
          error: action.payload.length < 10 ? "--" : "",
          messages: [
            ...action.payload,
            ...(state.directMessage.messages || []),
          ],
        },
      };

    case teamComActionTypes.ADD_TO_DMU_MESSAGES:
      return {
        ...state,
        directMessage: {
          ...state.directMessage,
          messages: [...(state.directMessage.messages || []), action.payload],
        },
      };

    case teamComActionTypes.ADD_TO_CHANNEL_MESSAGES:
      return {
        ...state,
        channels: {
          ...state.channels,
          messages: [...(state.channels.messages || []), action.payload],
        },
      };

    case teamComActionTypes.UPDATE_CHANNEL_MESSAGE:
      const updated_ch_msgs = update_or_add_msgs(
        state.channels.messages,
        action.payload.data,
        action.payload.add
      );
      return {
        ...state,
        other_error: "",
        other_loading: false,
        channels: {
          ...state.channels,
          messages: updated_ch_msgs,
        },
      };

    case teamComActionTypes.UPDATE_CHANNEL_MESSAGE_PROPS:
      const updated_msgs = find_and_update(
        state.channels.messages,
        action.payload.msg_id,
        action.payload.data
      );

      return {
        ...state,
        other_error: "",
        other_loading: false,
        channels: {
          ...state.channels,
          messages: updated_msgs,
        },
      };

    case teamComActionTypes.UPDATE_DIRECT_MESSAGE:
      const updated_dm_msgs = update_or_add_msgs(
        state.directMessage.messages,
        action.payload.data,
        action.payload.add
      );
      return {
        ...state,
        other_error: "",
        other_loading: false,
        directMessage: {
          ...state.directMessage,
          messages: updated_dm_msgs,
        },
      };

    case teamComActionTypes.UPDATE_DIRECT_MESSAGE_PROPS:
      const _updated_msgs = find_and_update(
        state.directMessage.messages,
        action.payload.msg_id,
        action.payload.data
      );

      return {
        ...state,
        other_error: "",
        other_loading: false,
        directMessage: {
          ...state.directMessage,
          messages: _updated_msgs,
        },
      };

    case teamComActionTypes.OTHER_FUNCTIONS_REQUEST:
      return {
        ...state,
        other_loading: true,
      };

    case teamComActionTypes.OTHER_FUNCTIONS_ERROR:
      return {
        ...state,
        other_loading: false,
        other_error: action.payload,
      };

    default:
      return state;
  }
};

export default teamComReducer;
