import axios from 'axios';
import { url } from './components/Constants/Constants';
import store from './store'
import { showLogoutWarning } from './actions/dashboardActions';



const axiosClient = axios.create({
    baseURL: url,
})

axiosClient.interceptors.request.use(function (config) {
    const token = store.getState().auth.token;
    
    if (token) {
        config.headers.Authorization =  `Token ${token}`;
    }
     
    return config;
});

axiosClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const { response } = error;

        if (response && response.status === 401) {    
            store.dispatch(showLogoutWarning())
            return new Promise(() => {});
        }

        return Promise.reject(error);
    }
);

export default axiosClient