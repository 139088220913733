import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  AppBar,
  Toolbar,
} from '@mui/material';
import { useHistory } from 'react-router-dom'; // Make sure to import useHistory

const TripPlannerViewDetails = ({ data,handleClose }) => {
//   const history = useHistory(); // Get history object
console.log(data);

  const {
    name,
    address,
    phone_number,
    category,
    map_image,
    latitude,
    longitude,
    rating,
    description,
    opening_hours,
  } = data;

  return (
    <Container maxWidth="lg">
      <AppBar position="static">
        <Toolbar>
          <Button
            color="inherit"
            onClick={() => handleClose()} // Go back to the previous page
            style={{ marginRight: '16px' }} // Add margin for spacing
          >
            Back
          </Button>
          <Typography variant="h6">{name}</Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={4} style={{ marginTop: '16px' }}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">{name}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardMedia
              component="img"
              alt={`${name} map`}
              image={map_image}
              title={name}
              height="300"
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={3} style={{ padding: '16px' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Basic Details
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                Address: {address}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                Phone: {phone_number}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                Category: {category?.display_name}
              </Typography>
              {rating && (
                <Typography variant="subtitle1" color="text.secondary">
                  Rating: {rating} ⭐
                </Typography>
              )}
              <Box marginTop={2}>
                <Button
                  variant="contained"
                  color="primary"
                  href={`https://www.google.com/maps?q=${latitude},${longitude}`}
                  target="_blank"
                  style={{ marginRight: '16px' }}
                >
                  View on Map
                </Button>
                <Button variant="outlined" color="secondary">
                  Contact Gallery
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Description
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {description || "Explore a wide range of artistic expressions and exhibitions at our gallery."}
              </Typography>
              <Typography variant="h5" component="div" style={{ marginTop: '16px' }}>
                Opening Hours
              </Typography>
              {opening_hours && false ? (
                Object.entries(opening_hours).map(([day, hours]) => (
                  <Typography key={day} variant="body2" color="text.secondary">
                    {day}: {hours}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Monday - Friday: 10 AM - 6 PM
                  <br />
                  Saturday - Sunday: 11 AM - 5 PM
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TripPlannerViewDetails