import * as React from "react";
import { Box, LinearProgress } from "@mui/material";

export default function FullScreenLoader({width="100%",height="100vh"}) {
  return (
    <Box
      sx={{
        display: "flex",
        width: width,
        height: height,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LinearProgress
        color="primary"
        fourColor={false}
        variant="query"
        sx={{ width: "50%" }}
      />
    </Box>
  );
}
