// import { configureStore } from '@reduxjs/toolkit';
import { createStore, applyMiddleware,compose } from "redux";

import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./reducer";

const debug = true;

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  whitelist: ["auth", "nav", "gen", "accMang", "filter", "settings"],
  timeout: 10000,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const customRootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    const { auth: authState,nav,gen,filter } = state;
    state = { auth: authState,nav,gen,filter };
  }
  return persistedReducer(state, action);
};
let store = createStore(
  customRootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
// const store = configureStore()

export const persistor = persistStore(store);

export default store;
