export const FETCH_TEAM_COM_CHANNELS_REQUEST =
  "FETCH_TEAM_COM_CHANNELS_REQUEST";
export const FETCH_TEAM_COM_CHANNELS_SUCCESS =
  "FETCH_TEAM_COM_CHANNELS_SUCCESS";
export const FETCH_TEAM_COM_CHANNELS_ERROR = "FETCH_TEAM_COM_CHANNELS_ERROR";
export const ADD_TEAM_COM_CHANNEL_REQUEST = "ADD_TEAM_COM_CHANNEL_REQUEST";
export const ADD_TEAM_COM_CHANNEL_SUCCESS = "ADD_TEAM_COM_CHANNEL_SUCCESS";
export const ADD_TEAM_COM_CHANNEL_ERROR = "ADD_TEAM_COM_CHANNEL_ERROR";
export const SET_TEAM_COM_SELECTED_CHANNEL = "SET_TEAM_COM_SELECTED_CHANNEL";
export const UNSET_SELECTED_CHANNEL = "UNSET_SELECTED_CHANNEL";
export const FETCH_CHANNEL_MESSAGES_REQUEST = "FETCH_CHANNEL_MESSAGES_REQUEST";
export const FETCH_CHANNEL_MESSAGES_SUCCESS = "FETCH_CHANNEL_MESSAGES_SUCCESS";
export const PREPEND_CHANNEL_MESSAGES = "PREPEND_CHANNEL_MESSAGES";
export const FETCH_CHANNEL_MESSAGES_FAILURE = "FETCH_CHANNEL_MESSAGES_FAILURE";
export const ADD_TO_CHANNEL_MESSAGES = "ADD_TO_CHANNEL_MESSAGES";
export const UPDATE_CHANNEL_MESSAGE = "UPDATE_CHANNEL_MESSAGE";
export const UPDATE_CHANNEL_MESSAGE_PROPS = "UPDATE_CHANNEL_MESSAGE_PROPS";
export const UNSET_CHANNEL_MESSAGES = "UNSET_CHANNEL_MESSAGES";

export const OTHER_FUNCTIONS_REQUEST = "OTHER_FUNCTIONS_REQUEST";
export const OTHER_FUNCTIONS_ERROR = "OTHER_FUNCTIONS_ERROR";

export const FETCH_DMU_REQUEST = "FETCH_DMU_REQUEST";
export const FETCH_DMU_SUCCESS = "FETCH_DMU_SUCCESS";
export const FETCH_DMU_ERROR = "FETCH_DMU_ERROR";
export const APPEND_TO_DMUS_LIST = "APPEND_TO_DMUS_LIST";
export const SET_SELECTED_DMU = "SET_SELECTED_DMU";
export const UNSET_SELECTED_DMU = "UNSET_SELECTED_DMU";
export const FETCH_DMU_MESSAGES_REQUEST = "FETCH_DMU_MESSAGES_REQUEST";
export const FETCH_DMU_MESSAGES_SUCCESS = "FETCH_DMU_MESSAGES_SUCCESS";
export const FETCH_DMU_MESSAGES_FAILURE = "FETCH_DMU_MESSAGES_FAILURE";
export const PREPEND_DIRECT_MESSAGES = "PREPEND_DIRECT_MESSAGES";
export const ADD_TO_DMU_MESSAGES = "ADD_TO_DMU_MESSAGES";
export const UPDATE_DIRECT_MESSAGE = "UPDATE_DIRECT_MESSAGE";
export const UPDATE_DIRECT_MESSAGE_PROPS = "UPDATE_DIRECT_MESSAGE_PROPS";
export const UNSET_DIRECT_MESSAGES = "UNSET_DIRECT_MESSAGES";
export const UPDATE_UNREAD_MSG_COUNT = "UPDATE_UNREAD_MSG_COUNT";
export const SET_UNREAD_COUNT= "SET_UNREAD_COUNT";
export const UPDATE_STAFF_UNREAD_MSG_COUNT= "UPDATE_STAFF_UNREAD_MSG_COUNT";
export const SET_INFO_FOR_CHAT_INP= "SET_INFO_FOR_CHAT_INP";
