export const FETCH_FILTER_REQUEST='FETCH_FILTER_REQUEST';
export const FETCH_FILTER_SUCCESS='FETCH_FILTER_SUCCESS';
export const FETCH_FILTER_ERROR='FETCH_FILTER_ERROR';
export const SET_SELECTED_FILTERS='SET_SELECTED_FILTERS';
export const FETCH_FILTER_TOPICS_REQUEST='FETCH_FILTER_TOPICS_REQUEST';
export const FETCH_FILTER_TOPICS_SUCCESS='FETCH_FILTER_TOPICS_SUCCESS';
export const FETCH_FILTER_TOPICS_ERROR='FETCH_FILTER_TOPICS_ERROR';
export const SET_SELECTED_FILTER_TOPICS='SET_SELECTED_FILTER_TOPICS';

export const SET_CURR_DATE = "SET_CURR_DATE";
export const SET_COMP_DATE = "SET_COMP_DATE";
export const SET_SELECTED_STAT_OPTION = "SET_SELECTED_STAT_OPTION";
export const SET_SELECTED_STAT_START_DATE = "SET_SELECTED_STAT_START_DATE";
export const SET_SELECTED_STAT_END_DATE = "SET_SELECTED_STAT_END_DATE";
export const SET_SELECTED_COMP_OPTION = "SET_SELECTED_COMP_OPTION";
export const SET_SELECTED_COMP_START_DATE = "SET_SELECTED_COMP_START_DATE";
export const SET_SELECTED_COMP_END_DATE = "SET_SELECTED_COMP_END_DATE";

export const FETCH_HOTEL_REQUEST='FETCH_HOTEL_REQUEST';
export const FETCH_HOTEL_SUCCESS='FETCH_HOTEL_SUCCESS';
export const FETCH_HOTEL_ERROR='FETCH_HOTEL_ERROR';
export const SET_SELECTED_HOTEL = 'SET_SELECTED_HOTEL';

export const SET_SELECTED_REVIEWID = 'SET_SELECTED_REVIEWID'
export const UNSET_SELECTED_FILTER = 'UNSET_SELECTED_FILTER'

export const FETCH_FILTER_SUCCESS_FOR_COM='FETCH_FILTER_SUCCESS_FOR_COM';
export const FETCH_FILTER_ERROR_FOR_COM='FETCH_FILTER_ERROR_FOR_COM';
export const SET_SELECTED_FILTERS_FOR_COM='SET_SELECTED_FILTERS_FOR_COM';
export const UNSET_SELECTED_FILTER_FOR_COM = 'UNSET_SELECTED_FILTER_FOR_COM'
export const SET_SELECTED_FILTERS_FOR_ALL = 'SET_SELECTED_FILTERS_FOR_ALL'
export const RESET_SELECTED_FILTER_EXCEPT_ME = 'RESET_SELECTED_FILTER_EXCEPT_ME'


