export const SET_TO_INITIAL = "SET_TO_INITIAL";
export const FETCH_GUEST_REQUESTS_REQUEST = "FETCH_GUEST_REQUESTS_REQUEST";
export const FETCH_GUEST_REQUESTS_SUCCESS = "FETCH_GUEST_REQUESTS_SUCCESS";
export const FETCH_GUEST_REQUESTS_ERROR = "FETCH_GUEST_REQUESTS_ERROR";
export const COMMON_TASK_REQUEST = "COMMON_TASK_REQUEST";
export const COMMON_TASK_ERROR = "COMMON_TASK_ERROR";
export const ADD_NEW_TASK_SUCCESS = "ADD_NEW_TASK_SUCCESS";
export const FETCH_FORM_DATA_SUCCESS = "FETCH_FORM_DATA_SUCCESS"

export const UPDATE_GUEST_REQUEST_SUCCESS = "UPDATE_GUEST_REQUEST_SUCCESS";
export const UPDATE_GUEST_REQUEST_ERROR = "UPDATE_GUEST_REQUEST_ERROR";

export const FETCH_STAFF_LIST_SUCCESS = "FETCH_STAFF_LIST_SUCCESS";
export const APPEND_STAFF_LIST_SUCCESS = "APPEND_STAFF_LIST_SUCCESS";

export const FETCH_ANALYTICS_REQUEST = "FETCH_ANALYTICS_REQUEST"
export const FETCH_ANALYTICS_SUCCESS = "FETCH_ANALYTICS_SUCCESS"
export const FETCH_ANALYTICS_ERROR = "FETCH_ANALYTICS_ERROR"
