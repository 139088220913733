import * as actionTypes from '../actions/actionTypes';

const initialState = {
globalMessage:{
        mess:'',
        type:'',
        position:""
      },
      showLogoutWarning:false
}

const commonReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.SET_GLOBAL_MESSAGE:{
        return {
          ...state,
          globalMessage: action.payload
        }
      }

      case actionTypes.SHOW_LOGOUT_WARNING:
        return {
          ...state,
          showLogoutWarning: true,
        };
        
    case actionTypes.SET_TO_INITIAL_COMMON_STORE:
      return initialState

    default:
      return state;
  }
}

export default commonReducer;