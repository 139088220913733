import { SET_TO_INITIAL } from "../../../actions/actionTypes";
import { UPDATE_UNREAD_MSG_COUNT } from "../../../apps/TeamCommunication/actions/teamComActionTypes";
import * as actionTypes from "./actions/actionTypes";
import notificationSound from "../../../assets/notification_sound.mp3";


const initialState = {
  loading: false,
  error: "",
  sideNavLinks: [],
  selectedNav: {},
  activeStaff: {},
  notificationData:{},
  notificationCountData:{
    unread_count:0,
    total_count:0
  }
};

function update_staff_in_list(lst, staff, add) {
  var _filtered_staffs = lst ? lst.filter((_s) => _s.id != staff.id) : [];

  if (add) return [..._filtered_staffs, staff];
  return _filtered_staffs;
}

const NavReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SIDENAV_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.FETCH_SIDENAV_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.FETCH_SIDENAV_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        sideNavLinks: action.payload.sort((a, b) =>
          a.sort_order > b.sort_order ? 1 : -1
        ),
      };

    case actionTypes.SET_SELCTED_SIDENAV:
      return {
        ...state,
        selectedNav: action.payload,
      };

    case actionTypes.FETCH_ACTIVE_STAFF_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.FETCH_ACTIVE_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        activeStaff: {
          ...state.activeStaff,
          [action.payload.id]: action.payload.data,
        },
      };

    case actionTypes.FETCH_ACTIVE_STAFF_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_ACTIVE_STAFF:
      const filtered_list = update_staff_in_list(
        state.activeStaff[action.payload.id],
        action.payload.staff,
        action.payload.add
      );

      return {
        ...state,
        activeStaff: {
          ...state.activeStaff,
          [action.payload.id]: filtered_list,
        },
      };

  case actionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notificationData:action.payload
    };

    case actionTypes.UPDATE_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData:{
          ...state.notificationData,
          results:action.payload
        }
    };

   case actionTypes.APPEND_NEW_NOTIFICATION:
      return {
        ...state,
        notificationData:{...state.notificationData,results:[action.payload,...state.notificationData.results],count:state.notificationData.count+1}
    };
    case UPDATE_UNREAD_MSG_COUNT:
      if(action.payload.filter === "Team Communication"){
        const notification_sound = new Audio(notificationSound);
        notification_sound.play().catch((error) => {
          console.error("Audio play was prevented:", error);
        });
      }
      return {
        ...state,
        sideNavLinks:state.sideNavLinks.map(e=>e.name === action.payload.filter?{...e,notif_count:action.payload.count}:e)
      };

    case actionTypes.SET_NOTIFICATION_UNREAD_COUNT:
        return {
          ...state,
          notificationCountData:action.payload
         
        };

    case SET_TO_INITIAL:
      return initialState;

    default:
      return state;
  }
};

export default NavReducer;
