import * as actionTypes from '../actions/actionTypes';

const initialState = {
  should_create_connection: false,
  connection_source: {} 
}


const accMangReducer = (state = initialState, action) => {

  switch(action.type){
    
    case actionTypes.Set_should_create_connection:
      return {
        ...state,
        should_create_connection: action.payload
      }
    
    case actionTypes.Set_connection_source:
      return {
        ...state,
        connection_source: action.payload
      }
    
    case actionTypes.SET_TO_INITIAL:
      return initialState;
    
    default:
      return state;
    
  }

}

export default accMangReducer;