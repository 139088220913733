export const FETCH_SIDENAV_REQUEST = 'FETCH_SIDENAV_REQUEST';
export const FETCH_SIDENAV_SUCCESS = 'FETCH_SIDENAV_SUCCESS';
export const FETCH_SIDENAV_ERROR = 'FETCH_SIDENAV_ERROR';

export const SET_SELCTED_SIDENAV = 'SET_SELCTED_SIDENAV';

export const FETCH_ACTIVE_STAFF_REQUEST = 'FETCH_ACTIVE_STAFF_REQUEST';
export const FETCH_ACTIVE_STAFF_SUCCESS = 'FETCH_ACTIVE_STAFF_SUCCESS';
export const FETCH_ACTIVE_STAFF_ERROR = 'FETCH_ACTIVE_STAFF_ERROR';
export const UPDATE_ACTIVE_STAFF = 'UPDATE_ACTIVE_STAFF';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const APPEND_NEW_NOTIFICATION = 'APPEND_NEW_NOTIFICATION';
export const SET_NOTIFICATION_UNREAD_COUNT = 'SET_NOTIFICATION_UNREAD_COUNT';
export const UPDATE_NOTIFICATION_DATA= 'UPDATE_NOTIFICATION_DATA';
